import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

// Asset Imports
import AimroLogo from '../images/svg/aimro-logo.inline.svg';

// Component Imports
import Container from './container';
import { NavLink, P2 } from './page-elements';

// Styled Components
const StyledFooter = styled.footer`
  border-top: 3px solid ${({ theme }) => theme.colors.primary.brand};
  text-align: center;
  /* background-color: ${({ theme }) => theme.colors.secondary[900]}; */
`;

const Row = styled.div`
  display: flex;
  /* flex-direction: row; */
  flex-direction: column-reverse;
  margin-bottom: ${({ theme }) => theme.spacing[16]};
  align-items: center;
  justify-content: space-between;

  @media (min-width: 64em) {
    flex-direction: row;
  }
`;

const Logo = styled(AimroLogo)`
  width: 10rem;
`;

const NavLinks = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing[8]};
  margin-bottom: ${({ theme }) => theme.spacing[8]};
`;

const UnderlineSpan = styled.span`
  position: relative;
  display: block;
  cursor: pointer;
  transition: transform 0.3s ease-in;
  ::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 2px;
    display: block;
    width: 0;
    background-color: ${({ theme }) => theme.colors.primary.brand};
    transition: width 0.3s ease-in;
  }

  &:hover {
    transform: translateY(-2px);
    ::after {
      width: 100%;
    }
  }
`;

const Disclaimer = styled(P2)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  padding-bottom: ${({ theme }) => theme.spacing[10]};

  @media (min-width: 37.5em) {
    font-size: ${({ theme }) => theme.fontSize.xs};
  }
`;

const Footer = () => (
  <StyledFooter>
    <Container>
      <Row>
        <Logo />
        <NavLinks>
          <NavLink to="/">
            <UnderlineSpan>Home</UnderlineSpan>
          </NavLink>
          <NavLink to="/standards">
            <UnderlineSpan>Standards</UnderlineSpan>
          </NavLink>
          <NavLink to="/members">
            <UnderlineSpan>Members</UnderlineSpan>
          </NavLink>
          <NavLink to="/membership">
            <UnderlineSpan>Membership</UnderlineSpan>
          </NavLink>
          <NavLink to="/contact">
            <UnderlineSpan>Contact</UnderlineSpan>
          </NavLink>
        </NavLinks>
      </Row>
      <Disclaimer>
        All Rights Reserved. <br />© {new Date().getFullYear()} AIMRO
      </Disclaimer>
    </Container>
  </StyledFooter>
);

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
