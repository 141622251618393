import styled from 'styled-components';
import rightArrow from '../../images/svg/right-arrow.svg';

export const B3 = styled.button`
  font-family: ${({ theme }) => theme.font.headings};
  font-size: ${({ theme }) => theme.fontSize['base']};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.secondary[300]};
  text-transform: uppercase;
  text-decoration: none;
  line-height: ${({ theme }) => theme.lineHeight['none']};
  letter-spacing: ${({ theme }) => theme.letterSpacing['widest']};
  background: ${({ theme }) => theme.colors.transparent};
  display: block;
  margin: 0 auto;
  position: relative;
  border: none;
  cursor: pointer;
  /* extra padding right to cover arrow */
  padding: ${({ theme }) => theme.spacing[4]}
    ${({ theme }) => theme.spacing[10]} ${({ theme }) => theme.spacing[4]}
    ${({ theme }) => theme.spacing[6]};
  transition: all 0.2s ease-in-out;

  &:focus {
    outline: none;
  }

  /* Arrow */
  &:after {
    content: '';
    background: url(${rightArrow}) center/contain no-repeat;
    height: 16px;
    width: 30px; /* Caps size */
    position: absolute;
    top: calc(50% - 8px);
    transition: all 0.2s ease-in-out;
  }

  /* Orange underline */
  &:before {
    content: '';
    background-color: ${({ theme }) => theme.colors.primary['brand']};
    height: 2px;
    width: 60%;
    position: absolute;
    bottom: 10%;
    /* center horizontally */
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    transform: translateY(-2px);
    &:after {
      transform: translateX(2px);
    }
  }
`;
