import styled from 'styled-components';

export const Tile = styled.div`
    background: ${({theme}) => theme.colors.white};
    border-radius: ${({theme}) => theme.borderRadius['lg']};
    box-shadow: ${({theme}) => theme.boxShadow['lg']};
    padding: ${({theme}) => theme.spacing[10]} ${({theme}) => theme.spacing[8]};
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
`;