import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Children } from 'react';
// import Test from "../../images/icons/standards.svg"

const Bubble = styled.div`
  background-color: ${({ theme }) => theme.colors.secondary[900]};
  border-radius: ${({ theme }) => theme.borderRadius['full']};
  padding: ${({ theme }) => theme.spacing[4]};
  width: min-content;
  height: min-content;
  line-height: 0;
  margin-bottom: ${({ theme }) => theme.spacing[5]};

  svg {
    width: ${({ theme }) => theme.spacing[8]};
    height: ${({ theme }) => theme.spacing[8]};
    fill: ${({ theme }) => theme.colors.secondary[200]};
  }
`;

export const BubbleIcon = ({ children }) => (
  <Bubble>
    {/* Test */}
    {/* <img
      alt={`${iconFilename}`}
      src={require(`../../images/icons/${iconFilename}.svg`)}
    /> */}
    {/* <img src={{Test}}/> */}
    {children}
  </Bubble>
);

// BubbleIcon.propTypes = {
//   iconFilename: PropTypes.string.isRequired,
// };
