import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

export const theme = {
  colors: {
    transparent: 'transparent',

    black: '#000',
    white: '#fff',

    primary: {
      brand: '#EC612B',
      100: '#712000',
      200: '#8A2E0A',
      300: '#99360F',
      400: '#B74315',
      500: '#C54F20',
      600: '#F1855B',
      700: '#FBA27E',
      800: '#FEC9B4',
      900: '#FCE3DA',
    },
    secondary: {
      brand: '#8CB8C3',
      100: '#103341',
      200: '#1D4959',
      300: '#2F5B6A',
      400: '#48707F',
      500: '#618B98',
      600: '#8CB8C3',
      700: '#A5C6CF',
      800: '#DAE8EC',
      900: '#F1F7F8',
      999: '#FAFCFD',
    },
    error: {
      100: '#e02c2c',
      200: '#fed7d7',
      300: '#feb2b2',
      400: '#fc8181',
      500: '#f56565',
      600: '#e53e3e',
      700: '#c53030',
      800: '#9b2c2c',
      900: '#742a2a',
    },
    success: {
      100: '#f0fff4',
      200: '#c6f6d5',
      300: '#9ae6b4',
      400: '#68d391',
      500: '#48bb78',
      600: '#38a169',
      700: '#2f855a',
      800: '#276749',
      900: '#22543d',
    },
  },
  font: {
    headings: '"brandon-grotesque", sans-serif',
    body: '"open-sans", sans-serif',
  },
  fontSize: {
    // @base 16px
    xs: '0.75rem', // 12px
    sm: '0.875rem', // 14px
    base: '1rem', // 16px
    lg: '1.125rem', // 18px
    xl: '1.25rem', // 20px
    '2xl': '1.5rem', // 24px
    '3xl': '1.875rem', // 30px
    '4xl': '2.25rem', // 36px
    '5xl': '3rem', // 48px
    '6xl': '4rem', // 64px
    '7xl': '5rem', // 80px
  },
  fontWeight: {
    regular: '400',
    bold: '700',
    black: '900',
  },
  letterSpacing: {
    tighter: '-0.05em',
    tight: '-0.025em',
    normal: '0',
    wide: '0.025em',
    wider: '0.05em',
    widest: '0.1em',
  },
  lineHeight: {
    none: '1',
    tight: '1.25',
    snug: '1.375',
    normal: '1.5',
    relaxed: '1.625',
    loose: '2',
  },
  spacing: {
    px: '1px',
    '0': '0',
    '1': '0.25rem',
    '2': '0.5rem',
    '3': '0.75rem',
    '4': '1rem',
    '5': '1.25rem',
    '6': '1.5rem',
    '8': '2rem',
    '10': '2.5rem',
    '12': '3rem',
    '16': '4rem',
    '20': '5rem',
    '24': '6rem',
    '32': '8rem',
    '40': '10rem',
    '48': '12rem',
    '56': '14rem',
    '64': '16rem',
    '72': '18rem',
  },
  borderRadius: {
    none: '0',
    sm: '0.125rem',
    default: '0.25rem',
    lg: '0.5rem',
    full: '9999px',
  },
  boxShadow: {
    default: '0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)',
    md: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    lg:
      '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    xl:
      '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
    '2xl': '0 25px 50px -12px rgba(0, 0, 0, 0.25)',
  },
};

export const GlobalStyle = createGlobalStyle`
  ${reset}

  *, *:before, *:after {
    box-sizing: border-box;
  }

  html {
    box-sizing: border-box;
    font-family: ${({ theme }) => theme.font.body};
    font-size: ${({ theme }) => theme.fontSize.base};
    color: ${({ theme }) => theme.colors.secondary[200]};
  }
  body {
    line-height: ${({ theme }) => theme.lineHeight.normal};
    /* letter-spacing: ${({ theme }) => theme.wide}; */
    background-color: ${({ theme }) => theme.colors.secondary[999]};
    overflow-x: hidden;
  }
`;
