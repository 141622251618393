import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledDiv = styled.div`
  /* border: 1px solid red; */
  margin: 0 auto;
  padding: 0 20px;

  @media (min-width: 37.5em) {
    padding: 0 35px;
  }

  @media (min-width: 64em) {
    width: 85%;
    max-width: 1400px;
  }

  @media (min-width: 90.063em) {
    width: auto;
  }
`;

const Container = ({ className, children }) => (
  <StyledDiv className={className}>{children}</StyledDiv>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Container;
