import styled from 'styled-components';

export const Badge = styled.button`
  background: ${({ theme }) => theme.colors.secondary[900]};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.full};
  padding: ${({ theme }) => theme.spacing[2]} ${({ theme }) => theme.spacing[5]};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: center;
  align-items: center;

  /* inner text */
  font-family: ${({ theme }) => theme.font.headings};
  font-weight: ${({ theme }) => theme.fontWeight.black};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.fontSize.sm};
  color: ${({ theme }) => theme.colors.secondary[400]};

  margin-right: ${({ theme }) => theme.spacing[5]};

  & > svg {
    min-height: ${({ theme }) => theme.fontSize.sm};
    min-width: ${({ theme }) => theme.fontSize.sm};
    margin-left: ${({ theme }) => theme.spacing[2]};
    fill: ${({ theme }) => theme.colors.secondary[500]};
    max-height: 1em; //To fix giant SVG icons -> Some web update caused this line to be necessary.
  }

  transition: all 0.2s ease-in-out;

  &:hover {
    transform: translateY(-2px);
    background: ${({ theme }) => theme.colors.secondary[800]};
  }

  &:focus {
    outline: none;
  }
`;
