import styled from 'styled-components';

export const P = styled.p`
  font-size: ${({ theme }) => theme.fontSize.xl};
  margin: 0 auto ${({ theme }) => theme.spacing[24]} auto;
  max-width: 80%;

  @media (min-width: 48em) {
    font-size: ${({ theme }) => theme.fontSize['2xl']};
    max-width: 60%;
  }
`;
