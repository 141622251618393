import styled from 'styled-components';
import { Link } from 'gatsby';

export const StyledLink = styled(Link)`
  text-decoration: none;
  /* background: none; */
  /* border: none; */
  align-items: flex-start;
  &:focus {
    outline: none;
  }
`;
