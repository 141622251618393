import styled from 'styled-components';

import AimroQuote from '../../images/svg/aimro-quote.svg';

export const H2 = styled.h2`
  font-size: ${({ theme }) => theme.fontSize['4xl']};
  font-family: ${({ theme }) => theme.font.headings};
  font-weight: ${({ theme }) => theme.fontWeight.black};
  color: ${({ theme }) => theme.colors.secondary[100]};
  text-transform: uppercase;
  text-align: center;
  line-height: ${({ theme }) => theme.lineHeight.none};
  letter-spacing: ${({ theme }) => theme.letterSpacing.widest};
  margin-top: ${({ theme }) =>
    theme.fontSize['2xl']}; /* Prevents anything overlapping quote shape */
  margin-bottom: ${({ theme }) => theme.spacing[20]};
  position: relative;

  strong {
    color: ${({ theme }) => theme.colors.primary.brand};
  }

  &:after {
    content: '';
    background: url(${AimroQuote}) center/contain no-repeat;
    height: 200%;
    width: 100px; /* Caps quote size */
    position: absolute;
    left: calc(50% - 50px);
    top: -45%;
    z-index: -1;
  }

  @media (min-width: 37.5em) {
    font-size: ${({ theme }) => theme.fontSize['5xl']};
  }
`;
