import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import { HamburgerCollapse } from 'react-animated-burgers';
import Container from './container';
import { NavLink } from './page-elements';

// TODO Remove
import AimroLogoWhite from '../images/svg/aimro-logo-white.inline.svg';

// Hooks
import useDocumentScrollThrottled from '../hooks/useDocumentScrollThrottled';

// Styled Components
const StyledHeader = styled.header`
  /* transform: ${props =>
    props.shouldHideHeader ? `translateY(-100px)` : 'none'}; */
  width: 100%;
  transition: all 0.3s ease-in-out;

  @media(min-width: 48em){
    /* Make header fixed on larger screens */
    position: fixed;
    top: 0;
    z-index: 2;
  }
`;

const HeaderDiv = styled.div`
  background: ${({ theme }) => theme.colors.primary.brand};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Logo = styled(AimroLogoWhite)`
  /* height: 50px; */
  width: 10rem;
  padding: ${({ theme }) => theme.spacing[8]} ${({ theme }) => theme.spacing[8]}
    ${({ theme }) => theme.spacing[8]} 0;
  transform: translateY(1px);
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.01);
  }
`;

const NavLinks = styled.div`
  display: none;
  justify-content: center;
  margin: 0;

  @media (min-width: 48em) {
    display: flex;
  }
`;

const UnderlineSpan = styled.span`
  position: relative;
  display: block;
  cursor: pointer;
  transition: transform 0.3s ease-in;
  ::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    height: 2px;
    display: block;
    width: 0;
    background-color: ${({ theme }) => theme.colors.white};
    transition: width 0.3s ease-in;
  }

  &:hover {
    transform: translateY(-2px);
    ::after {
      width: 100%;
    }
  }
`;

const MobileMenuButton = styled(HamburgerCollapse)`
  z-index: 5;
  margin-left: auto;
  padding-right: 0;
  &:focus {
    outline: none;
  }
  @media (min-width: 48em) {
    display: none;
  }
`;

const MobileNav = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: ${props => (props.mobileMenuOpen ? `100%` : 0)};
  width: 100%;
  transition: height 0.2s ease-in 0.2s;
  background: ${({ theme }) => theme.colors.white};
  z-index: 4;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const MobileNavLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0 ${({ theme }) => theme.spacing[8]};
  text-align: right;
  margin-left: auto;
`;

const MobileNavLink = styled(NavLink)`
  padding-right: 0;
`;

const Header = () => {
  // const [shouldHideHeader, setShouldHideHeader] = useState(false);
  // const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // const MINIMUM_SCROLL = 500;
  // const TIMEOUT_DELAY = 10;

  // useDocumentScrollThrottled((callbackData) => {
  //   const { previousScrollTop, currentScrollTop } = callbackData;
  //   const isScrolledDown = previousScrollTop < currentScrollTop;
  //   const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

  //   setTimeout(() => {
  //     setShouldHideHeader(isScrolledDown && isMinimumScrolled);
  //   }, TIMEOUT_DELAY);
  // });
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <Helmet>
        <style>{`html { overflow-y: ${
          mobileMenuOpen ? `hidden` : `scroll`
        }}`}</style>
      </Helmet>
      <StyledHeader shouldHideHeader={false}>
        <HeaderDiv>
          <Container>
            <Row>
              <Link
                to="/"
                style={{
                  color: `white`,
                  textDecoration: `none`,
                }}
              >
                <Logo />
              </Link>
              <NavLinks>
                <NavLink white={1} to="/standards">
                  <UnderlineSpan>Standards</UnderlineSpan>
                </NavLink>
                <NavLink white={1} to="/polling-questions">
                  <UnderlineSpan>Polls</UnderlineSpan>
                </NavLink>
                <NavLink white={1} to="/members">
                  <UnderlineSpan>Members</UnderlineSpan>
                </NavLink>
                <NavLink white={1} to="/membership">
                  <UnderlineSpan>Join</UnderlineSpan>
                </NavLink>
                <NavLink white={1} to="/contact">
                  <UnderlineSpan>Contact</UnderlineSpan>
                </NavLink>
              </NavLinks>
              <MobileMenuButton
                isActive={mobileMenuOpen}
                toggleButton={() => setMobileMenuOpen(!mobileMenuOpen)}
                barColor={mobileMenuOpen ? 'black' : 'white'}
              />
            </Row>
          </Container>
        </HeaderDiv>
      </StyledHeader>
      <MobileNav mobileMenuOpen={mobileMenuOpen}>
        <MobileNavLinks>
          <MobileNavLink to="/">
            <UnderlineSpan>Home</UnderlineSpan>
          </MobileNavLink>
          <MobileNavLink to="/standards">
            <UnderlineSpan>Standards</UnderlineSpan>
          </MobileNavLink>
          <MobileNavLink to="/polling-questions">
            <UnderlineSpan>Polls</UnderlineSpan>
          </MobileNavLink>
          <MobileNavLink to="/members">
            <UnderlineSpan>Members</UnderlineSpan>
          </MobileNavLink>
          <MobileNavLink to="/membership">
            <UnderlineSpan>Join Aimro</UnderlineSpan>
          </MobileNavLink>
          <MobileNavLink to="/contact">
            <UnderlineSpan>Contact</UnderlineSpan>
          </MobileNavLink>
        </MobileNavLinks>
      </MobileNav>
    </>
  );
};
export default Header;
