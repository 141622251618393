import styled from 'styled-components';

export const P2 = styled.p`
  font-size: ${({ theme }) => theme.fontSize['base']};
  color: ${({ theme }) => theme.colors.secondary[300]};

  @media (min-width: 37.5em) {
    font-size: ${({ theme }) => theme.fontSize['lg']};
  }
`;
