import styled from 'styled-components';

export const H4 = styled.h4`
  font-size: ${({ theme }) => theme.fontSize['lg']};
  font-family: ${({ theme }) => theme.font.headings};
  font-weight: ${({ theme }) => theme.fontWeight.black};
  color: ${({ theme }) => theme.colors.secondary[200]};
  text-transform: uppercase;
  letter-spacing: ${({ theme }) => theme.letterSpacing.widest};
  margin-bottom: ${({ theme }) => theme.spacing[8]};

  @media (min-width: 37.5em) {
    font-size: ${({ theme }) => theme.fontSize['xl']};
  }
`;
