import styled from 'styled-components';

export const B1 = styled.button`
  font-family: ${({ theme }) => theme.font.headings};
  font-size: ${({ theme }) => theme.fontSize.base};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  line-height: ${({ theme }) => theme.lineHeight.none};
  letter-spacing: ${({ theme }) => theme.letterSpacing.widest};
  background: ${({ theme }) => theme.colors.primary.brand};
  /* display: block; */
  position: relative;
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius.lg};
  cursor: pointer;
  /* extra padding right to cover arrow */
  padding: ${({ theme }) => theme.spacing[5]} ${({ theme }) => theme.spacing[8]};
  transition: all 0.2s ease-in-out;

  &:focus {
    outline: none;
  }

  &:hover {
    transform: translateY(-2px);
  }
`;
