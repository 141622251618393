import styled from 'styled-components';
import { B3 } from './b3';

import leftArrow from '../../images/svg/left-arrow.svg';

export const BackButton = styled(B3)`
  /* extra padding right to cover arrow */
  padding: ${({ theme }) => theme.spacing[4]} ${({ theme }) => theme.spacing[6]}
    ${({ theme }) => theme.spacing[4]} ${({ theme }) => theme.spacing[10]};
  margin: 0;
  margin-right: auto;
  margin-bottom: ${({ theme }) => theme.spacing[32]};

  /* Arrow */
  &:after {
    content: '';
    background: url(${leftArrow}) center/contain no-repeat;
    height: 16px;
    width: 30px; /* Caps size */
    position: absolute;
    top: calc(50% - 8px);
    left: 0;
    transition: all 0.2s ease-in-out;

    &:hover {
      &:after {
        transform: translateX(-2px);
      }
    }
  }
`;
