import styled from 'styled-components';

export const Col3 = styled.div`
  --gap: ${({ theme }) => theme.spacing[8]};
  /* gap: 2rem; If only. */
  display: flex;
  flex-direction: column;
  /* This is a trick to emulate flex gap on browsers that dont support it (most of them) */
  margin: calc(-1 * var(--gap)) 0 ${({ theme }) => theme.spacing[24]}
    calc(-1 * var(--gap));

  > div {
    margin: var(--gap) 0 0 var(--gap);
  }

  @media (min-width: 64em) {
    flex-direction: row;
  }
`;
