import styled from 'styled-components';

export const H3 = styled.h3`
  font-size: ${({ theme }) => theme.fontSize.base};
  font-family: ${({ theme }) => theme.font.headings};
  font-weight: ${({ theme }) => theme.fontWeight.black};
  color: ${({ theme }) => theme.colors.secondary[300]};
  text-transform: uppercase;
  text-align: center;
  letter-spacing: ${({ theme }) => theme.letterSpacing.widest};
  margin-bottom: ${({ theme }) => theme.spacing[24]};

  @media (min-width: 37.5em) {
    font-size: ${({ theme }) => theme.fontSize.lg};
    margin-bottom: ${({ theme }) => theme.spacing[32]};
  }
`;
