import styled from 'styled-components';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

export const NavLink = styled(Link)`
  font-family: ${({ theme }) => theme.font.headings};
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.black};

  text-transform: uppercase;
  color: ${props =>
    props.white
      ? ({ theme }) => theme.colors.white
      : ({ theme }) => theme.colors.secondary[300]};
  padding: ${({ theme }) => theme.spacing[8]} ${({ theme }) => theme.spacing[6]};
  text-decoration: none;
  cursor: pointer;
`;

NavLink.propTypes = {
  white: PropTypes.number, // Bool is being weird
};
